<template>
  <div>
    <div id="contacts-content">
      <div id="contacts-wrapper">
        <h1 id="contacts-naming">{{ $t("message.ContactsTitle") }}</h1>
        <h1 id="contacts-naming-lower">{{ $t("message.CompanyAdress") }}</h1>
        <a id="text-st">{{ $t("message.WeCreateSys") }}</a>
        <div id="contact-informs">
          <div id="contact-row">
            <v-icon x-large>mdi-map-marker</v-icon>
            <div id="contact-sub">
              <a id="bold">{{ $t("message.Adress") }}</a>
              <a>{{ $t("message.AdressSub") }}</a>
            </div>
          </div>
          <div id="contact-row">
            <v-icon x-large>mdi-phone</v-icon>
            <div id="contact-sub">
              <a id="bold">{{ $t("message.Phone") }}</a>
              <a>{{ $t("message.HeadPhone") }}</a>
            </div>
          </div>
          <div id="contact-row">
            <v-icon x-large>mdi-email</v-icon>
            <div id="contact-sub">
              <a id="bold">E-mail</a>
              <a>{{ $t("message.HeadEmail") }}</a>
            </div>
          </div>
          <div id="contact-row">
            <v-icon x-large>mdi-clock</v-icon>
            <div id="contact-sub">
              <a id="bold">{{ $t("message.TimeTitle") }}</a>
              <a>{{ $t("message.TimeText") }}</a>
            </div>
          </div>
        </div>
      </div>
      <div id="rcol">
        <div>
          <span v-html="$t('message.Mapiframe')"></span>
        </div>
      </div>
    </div>
    <div id="request">
      <h1 id="naming">{{ $t("message.ReqKP") }}</h1>
      <div id="req-content">
        <div id="req-text">{{ $t("message.ConsultText") }}</div>
        <div id="form-left">
          <form>
            {{ $t("message.YourName") }}
            <v-text-field
              v-model="message.name"
              :counter="30"
              label="Name"
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
            {{ $t("message.YourPhone") }}
            <v-text-field
              v-model="message.title"
              :items="items"
              label="Phone"
              required
              @change="$v.select.$touch()"
              @blur="$v.select.$touch()"
            ></v-text-field>
            {{ $t("message.YourEmail") }}
            <v-text-field
              v-model="message.emailField"
              :error-messages="emailErrors"
              label="E-mail"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            ></v-text-field>
            {{ $t("message.YourProduct") }}
            <v-text-field
              v-model="message.message"
              :error-messages="productErrors"
              label="Product"
              @input="$v.product.$touch()"
              @blur="$v.product.$touch()"
            ></v-text-field>
            <!-- <v-checkbox
              v-model="checkbox"
              :error-messages="checkboxErrors"
              label="Do you agree?"
              required
              @change="$v.checkbox.$touch()"
              @blur="$v.checkbox.$touch()"
            ></v-checkbox> -->

            <v-btn color="pink lighten-1" width="200px" class="mr-4" v-on:click="SendEmail">
              {{ $t("message.Submit") }}
            </v-btn>
            <v-btn @click="clear"> {{ $t("message.Clear") }} </v-btn>
          </form>
        </div>
        <!-- <div id="form-right"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
const axios = require("axios").default;

export default {
  mixins: [validationMixin],

  validations: {
    name: { required, maxLength: maxLength(30) },
    email: { email },
    select: {},
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  data: () => ({
    // name: "",
    // email: "",
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
    config: "",
    message: {
      name: "",
      emailField: "",
      title: "",
      message: "",
    },
  }),

  computed: {
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength && errors.push("Name must be at most 10 characters long");
      !this.$v.name.required && errors.push("");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
    },
    clear() {
      this.$v.$reset();
      this.message.name = "";
      this.message.emailField = "";
      this.message.title = "";
      this.message.message = "";
      this.select = null;
      this.checkbox = false;
    },
    async SendEmail() {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let request = axios.post(this.config + "/SendEmail", this.message);
      // let request = await axios.post('http://localhost:5542/api/Samupak/SendEmail',this.message)
      if (request.status && request.data) {
        this.message = {
          name: "",
          emailField: "",
          title: "",
          message: "",
        };
      } else {
        console.log("error sending feedback");
      }
    },
  },
};
</script>

<style>
#contacts-wrapper {
  display: flex;
  flex-direction: column;
}
#contacts-content {
  height: 100%;
  width: 100%;
  padding-left: 25%;
  padding-right: 25%;
  padding-top: 160px;
  display: flex;
}
#rcol {
  margin-left: 5%;
}
#text-st {
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#contacts-naming-lower {
  font: 20px "Open Sans", sans-serif;
  font-weight: bold;
  display: inline;
  padding-top: 20px;
}
#contact-informs {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: flex-start;
}
#contact-row {
  display: flex;
  padding-top: 15px;
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#contact-sub {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
#request {
  background-color: rgb(245, 245, 245);
  min-height: 100px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 25%;
  padding-right: 25%;
  align-items: flex-start;
  font: 14px "Open Sans", sans-serif;
  padding-top: 20px;
  margin-bottom: -400px;
}
#req-content {
  display: flex;
  font: 13px/20px Ubuntu, Arial, sans-serif;
}
#bold {
  font-weight: 600;
}
#req-text {
  margin-right: 20px;
  width: 33%;
}
#form-left {
  margin-left: 5%;
  width: 66%;
}
/* #form-right {
  
} */
@media (max-width: 1140px) {
  #contacts-content {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
  }
  #request {
    padding-left: 5%;
    padding-right: 1%;
    margin-top: 50px;
  }
  #req-content {
    min-height: 100px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 1%;
    align-items: flex-start;
    font: 14px "Open Sans", sans-serif;
    padding-top: 20px;
    margin-bottom: -180px;
  }
  #req-text {
    margin-right: 20px;
    width: 90%;
  }
  #form-left {
    margin-left: 5%;
    width: 90%;
    padding-top: 20px;
  }
}
@media (max-width: 700px) {
  #contacts-content {
    padding-top: 220px;
  }
  #map {
    width: 450px;
  }
  #rcol {
    margin-left: 0%;
  }
}
@media (max-width: 500px) {
  #map {
    width: 300px;
  }
}
</style>
